function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

const ROOTS_DASHBOARD = '/dashboard';
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: path(ROOTS_DASHBOARD, '/app'),
};

const ROOTS_ACCOUNT = '/account';
export const PATH_ACCOUNT = {
  root: ROOTS_ACCOUNT,
  transaction: path(ROOTS_ACCOUNT, '/transaction'),
  withdrawal: path(ROOTS_ACCOUNT, '/withdrawal'),
};

const ROOTS_INVESTMENT = '/investment';
export const PATH_INVESTMENT = {
  root: ROOTS_INVESTMENT,
  discover: path(ROOTS_INVESTMENT, '/discover'),
  term: path(ROOTS_INVESTMENT, '/discover/term'),
  contract: path(ROOTS_INVESTMENT, '/discover/contract'),
  stake: path(ROOTS_INVESTMENT, '/stake'),
  portfolio: path(ROOTS_INVESTMENT, '/portfolio'),
};

const ROOTS_TEAM = '/team';
export const PATH_TEAM = {
  root: ROOTS_TEAM,
};

const ROOTS_SETTINGS = '/settings';
export const PATH_SETTINGS = {
  root: ROOTS_SETTINGS,
};

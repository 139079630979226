import { Suspense, lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

import DashboardLayout from './layouts/dashboard';

import GuestGuard from './guards/GuestGuard';
import AuthGuard from './guards/AuthGuard';

import {
  PATH_DASHBOARD,
  PATH_AUTH,
  PATH_ACCOUNT,
  PATH_SETTINGS,
  PATH_TEAM,
  PATH_INVESTMENT,
} from './paths';

import LoadingScreen from './components/LoadingScreen';

const Loadable = Component => props => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: PATH_AUTH.root,
      children: [
        {
          path: `${PATH_AUTH.login}`,
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: `${PATH_AUTH.register}`,
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: `${PATH_AUTH.forgotPassword}`,
          element: <ForgotPassword />,
        },
        {
          path: `${PATH_AUTH.resetPassword}`,
          element: <ResetPassword />,
        },
      ],
    },
    {
      path: PATH_DASHBOARD.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATH_DASHBOARD.app} replace />,
          index: true,
        },
        { path: `${PATH_DASHBOARD.app}`, element: <Dashboard /> },
      ],
    },
    {
      path: PATH_INVESTMENT.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATH_INVESTMENT.discover} replace />,
          index: true,
        },
        {
          path: `${PATH_INVESTMENT.discover}`,
          element: <Discover />,
        },
        {
          path: `${PATH_INVESTMENT.term}`,
          element: <Term />,
        },
        {
          path: `${PATH_INVESTMENT.stake}`,
          element: <Stake />,
        },
        {
          path: `${PATH_INVESTMENT.contract}`,
          element: <Contract />,
        },
        {
          path: `${PATH_INVESTMENT.portfolio}`,
          element: <Portfolio />,
        },
      ],
    },
    {
      path: PATH_ACCOUNT.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATH_ACCOUNT.transaction} replace />,
          index: true,
        },
        {
          path: `${PATH_ACCOUNT.transaction}`,
          element: <Transaction />,
        },
        {
          path: `${PATH_ACCOUNT.withdrawal}`,
          element: <Withdrawal />,
        },
      ],
    },
    {
      path: PATH_TEAM.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <Team />, index: true }],
    },
    {
      path: PATH_SETTINGS.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <Settings />, index: true }],
    },
    {
      path: '/',
      element: <Navigate to={PATH_DASHBOARD.app} replace />,
      index: true,
    },
  ]);
}

const Login = Loadable(lazy(() => import('./pages/auth/Login')));
const Register = Loadable(lazy(() => import('./pages/auth/Register')));
const ForgotPassword = Loadable(
  lazy(() => import('./pages/auth/ForgotPassword'))
);
const ResetPassword = Loadable(
  lazy(() => import('./pages/auth/ResetPassword'))
);
const Dashboard = Loadable(lazy(() => import('./pages/Dashboard')));
const Team = Loadable(lazy(() => import('./pages/organization/Team')));
const Settings = Loadable(lazy(() => import('./pages/Settings')));
const Transaction = Loadable(lazy(() => import('./pages/account/Transaction')));
const Withdrawal = Loadable(lazy(() => import('./pages/account/Withdrawal')));
const Discover = Loadable(lazy(() => import('./pages/investment/Discover')));
const Term = Loadable(lazy(() => import('./pages/investment/Term')));
const Stake = Loadable(lazy(() => import('./pages/investment/Stake')));
const Contract = Loadable(lazy(() => import('./pages/investment/Contract')));
const Portfolio = Loadable(lazy(() => import('./pages/investment/Portfolio')));
